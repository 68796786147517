<!--模型列表组件-->
<template>
  <el-skeleton
      style="width: 100%"
      :loading="loading"
      animated
      :count="recommendList.length + 4"
  >
    <div slot="template">
      <el-skeleton-item
          variant="image"
          style="width: 3.2rem; height: 2.7rem; margin-bottom: 0.4rem"
      />
    </div>
    <div class="recommendList" v-if="recommendList.length > 0">
      <router-link
          :to="{ path: activeItem.path, query: { detailId: item.pid } }"
          target="_blank"
          class="recommendItem"
          v-for="(item, index) in recommendList"
          :key="index"
      >
        <div class="recommendItemImg" :class="{recommendCourseItemImg: activeItem.type == 6}">
          <img :src="item.img" alt="" />
        </div>
        <div class="recommendItemTitle">{{ item.title }}</div>
      </router-link>
      <div style="height: 0; width: 3.2rem"></div>
      <div style="height: 0; width: 3.2rem"></div>
      <div style="height: 0; width: 3.2rem"></div>
    </div>
    <div v-else>
      <el-empty description="没有内容哦"></el-empty>
    </div>
  </el-skeleton>
</template>

<script>
export default {
  name: "RecommendList",
  props: {
		activeItem: {
			activeItem: Object,
			default: () => {}
		},
    recommendList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created() {
		
	},
};
</script>

<style scoped>
.el-empty{
	width:100%
}
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recommendItemTitle {
  width: 3.24rem;
	font-size: 0.14rem;
	font-weight: 500;
	color: rgba(44, 44, 52, 1);
  margin-top: 0.08rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.recommendItem {
  width: 3.24rem;
  padding-right: 0.32rem;
  margin-bottom: 0.32rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.recommendItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}

.recommendItemImg {
  width: 3.24rem;
  height: 2.44rem;
  border-radius: 0.08rem;
  overflow: hidden;
}
.recommendItemImg img {
  width: 3.24rem;
  height: 2.43rem;
	object-fit: cover;
}
.recommendCourseItemImg {
	height: 1.94rem;
}
.recommendCourseItemImg img {
	height: 1.94rem;
}
::v-deep .recommendList {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>