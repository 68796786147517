<template>
  <div style="width: 100%;background:#F0F3F8;min-width: 1440px">
    <Helper></Helper>
    <!--顶部导航栏-->
    <Navbar2 class="navHeader" @searchKey="getKey" navBg="ffffff" serachBorder="detail"></Navbar2>
    <div class="topBox">
      <div class="topContainerBox">
        <div class="topContainerContentBox">
          <video @contextmenu.prevent controlslist="nodownload noremoteplayback" width="224" height="126" controls style="position: absolute;top:30%;right:4%;border-radius: 0.08rem" v-if="info.video">
            <source :src="info.video">
<!--            <source src="../../assets/video/2.mp4">-->
            您的浏览器不支持 video 标签。
          </video>
          <div class="ttopbox">
            <img :src="info.icon" alt="">
            <div>
              <div class="topDesc">{{info.title}} <el-tooltip class="item" effect="dark" content="任何单位或个人认为本网页内容可能涉嫌侵犯其合法权益，请及时和[知设网]联系。本网站将会第一时间移除相关涉嫌侵权的内容。[知设网]上关于用户或其发布的相关内容均由用户自行提供，用户依法应对其提供的任何信息承担全部责任，本网站不对此承担任何法律责任。" placement="bottom">
               <!-- <i class="el-icon-question"></i> -->
								<img class="question" src="../../assets/image/freeSoftware/question.png" alt="">
              </el-tooltip></div>
              <div class="topIcon">
                <div><img src="../../assets/image/freeSoftware/user.png" alt="">{{info.author}}</div>
                <div><img src="../../assets/image/freeSoftware/safe.png" alt="">无病毒 安全下载</div>
              </div>
            </div>
          </div>
          <div class="midBox">
            <div>
              <div style="width:2rem; margin-right:0.3rem">界面语言：{{info.language === 1 ? '中文' : '英文'}}</div>
              <div>运行系统：{{info.system}}</div>
            </div>
            <div>
              <div style="width:2rem; margin-right:0.3rem">运行环境：{{info.environment}}</div>
              <div style="color: rgba(54, 51, 51, 1);font-size: 0.2rem;font-weight: 700;"  v-if="!butUrl.length && info.baiduCode">提取码：{{info.baiduCode}}</div>
            </div>
          </div>
          <div class="bottomBox">
            <el-button v-if="!butUrl.length" style="width:2.84rem;height:0.58rem;font-size: 0.24rem" type="primary" size="large" @click="goYun()">网盘下载</el-button>
            <el-button
              v-for="(i, index) in butUrl" 
              :key="index"
              style="width:2.84rem;height:0.58rem;font-size: 0.24rem" 
              type="primary" 
              size="large"
              @click="goYun(i.type)">
              {{i.name}}
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div style="width:100%;background: rgba(252, 252, 252, 1);padding-top:0.28rem">
			<div class="recommendBox">
				<div class="userMenuList">
					<div
							class="userMenuItem"
							:class="{ active: type == item.type }"
							v-for="item in menuList"
							:key="item.type"
							@click="changeTab(item.type, item)"
					>
						{{ item.title }}
					</div>
				</div>
				<div class="mainContent" :class="{ mainContentMax: type != 6 && list.length > 6}">
					<RecommendList :activeItem="activeItem" :recommendList="list" :loading="loading"></RecommendList>
				</div>
				<div class="t_c" style="margin-bottom: 0.3rem">
					<el-pagination
						v-show="type != 6"
						background
						layout="prev, pager, next"
						@current-change="setPage"
						:total="totalPage"
						:current-page="page"
						:page-size="page_size"
					></el-pagination>
				</div>
			</div>
      <div class="bottomDescBox">
        <div class="descTitleBox"><div style="height:0.12rem;width:0.12rem;border-radius:50%;background:rgba(1, 152, 255, 1);margin-right: 0.3rem"></div>软件介绍<div style="height:0.12rem;width:0.12rem;border-radius:50%;background:rgba(1, 152, 255, 1);margin-left:0.3rem"></div></div>
        <div
          v-if="info.content"
          class="descContentBox"
          v-html="info.content"
        ></div>
        <div v-else class="descContentBox">
          {{info.introduce}}
        </div>
      </div>
    </div>
    <Footer style="z-index:12"></Footer>
  </div>
</template>
<script>
import RecommendList from "@/components/RecommendList";
import Navbar2 from "./componts/Navbar2";
import {
  Footer
} from "../../layout/componts";
import { 
	getDetail,
	getRecommendList,
	checkDownTimes,
	} from "../../Api";
import { getToken } from "../../utils/auth";
import Helper from "./componts/helper"
import {mapGetters} from "vuex";
export default {
  name: "SoftwareDetailIndex",
  components: {
    Navbar2,
    Footer,
    Helper,
		RecommendList
  },
  mounted() {
  },
  destroy() {
  },
  data() {
    return {
      id: '',
      info: {},
      loading: true,
      page: 1,
      page_size: 12,
      totalPage: 0,
			list: [],
			type: 6,
			activeItem: {
				type: 6,
				title: '课程',
				path: '/course/detail'
			},
			menuList: [{
				type: 6,
				title: '课程',
				path: '/course/detail'
			},{
				type: 561,
				title: 'SU模型',
				path: '/su/detail'
			},{
				type: 1393,
				title: '3D模型',
				path: '/max/detail'
			},{
				type: 1196,
				title: 'PS素材',
				path: '/ps/detail'
			},{
				type: 1394,
				title: '方案文本',
				path: '/schemeText/detail'
			}],
      
      
      nameArr: [{
        name: '度盘下载',
        type: 2,
        key: 'baidu'
      }, {
        name: '夸克下载',
        type: 3,
        key: 'quark'
      }, {
        name: '迅雷下载',
        type: 1,
        key: 'xunlei'
      }],
      butUrl: [],
    }
  },
  created() {
    this.id = this.$route.query.id
    let params = {
      id: this.id
    }
    getDetail(params).then(res=>{
      if(res.status === '200'){
        this.info = res.data
        if (this.info.filePathArr) {
          let arr = this.info.filePathArr.split(',')
          this.butUrl = arr.map((i)=>{
            let name = '下载';
            let type = 1;
            this.nameArr.forEach((item)=>{
              let flag = i.includes(item.key)
              if (flag) {
                name = item.name
                type = item.type
              }
            })
            return {
              url: i,
              type,
              name
            }
          })
        }
      }
    })
		this.changeTab(6, this.activeItem);
  },
  computed: {
    ...mapGetters(["isLogin"]),
  },
  methods: {
    //分页
    setPage(val) {
      this.page = val;
      this.getDataList();
    },
		//切换类型
		changeTab(type, item) {
			this.page = 1;
			this.totalPage = 0;
			this.type = type;
			this.list = [];
			this.activeItem = item
			this.getDataList();
		},
		//获取列表数据
		getDataList() {
			const params = {
				page: this.page,
				per_page: this.page_size
			};
			//获取课程
			if (this.type == 6) {
        this.loading = true;
        params.per_page = 4;
        params.fid = this.type;
        getRecommendList(params).then((res) => {
          this.list = res.data;
          this.loading = false;
        });
			} else {
        this.loading = true;
        params.per_page = 20;
        params.fid = this.type;
        getRecommendList(params).then((res) => {
          this.list = res.data.data;
          this.totalPage = res.data.total;
          this.page_size = params.per_page
          this.loading = false;
        });
      }
		},
    goYun(nameType){
      if(!this.isLogin){
        this.$login()
        return
      }
			checkDownTimes({token: getToken(), id: this.info.id}).then((res) => {
				if (res.data&&res.data.error == 0) {
          let downloadUrl
          if (nameType) {
            downloadUrl = this.$ROOT_DIR + "/web/CloudLibraryDetail/down?id=" + this.info.id + "&fileType=" + nameType + "&token=" + getToken();
          } else{
            downloadUrl = this.$ROOT_DIR + "/web/CloudLibraryDetail/down?id=" + this.info.id + "&token=" + getToken();
          }
					let link = document.createElement("a");
					link.style.display = "none";
					link.target = '_blank';
					link.href = downloadUrl;
					document.body.appendChild(link);
					link.click();
				} else if (res.data&&res.data.error == -1) {
					this.$tooltipPop().show(1, res.data.msg)
				} else if (res.data&&res.data.error == -2) {
					this.$tooltipPop().show(2, res.data.msg)
				} else {
					this.$tooltipPop().show(0, res.data.msg)
				}
			}).catch((err) => {
				console.log(err);
			});
    },
    getKey(){}
  }
};
</script>
<style lang="css">
.el-tooltip__popper{
  max-width: 2.4rem !important;
}
</style>
<style scoped>
.recommendBox {
	min-height: 0.5rem;
}
.userMenuList {
	width: 14.4rem;
	height: 0.42rem;
	margin: 0 auto;
	display: flex;
	justify-items: center;
}
.userMenuItem {
	height: 0.42rem;
	line-height: 0.42rem;
	border-radius: 0.21rem;
	padding: 0 0.24rem;
	margin-right: 0.24rem;
	font-size: 0.18rem;
	font-weight: 400;
	background-color: rgba(240, 243, 248, 1);
	cursor: pointer;
	transition: 0.3s;
}
.userMenuItem:hover {
	color: #fff;
	background: rgb(68, 179, 255, 0.9);
  transform: translateY(-2px);
}
.mainContent {
	width: 14.4rem;
	min-height: 2.2rem;
	margin: 0.24rem auto;
	transition: all 0.3s ease-in;
}
.mainContentMax {
	min-height: 9rem;
}
.userMenuItem.active {
	color: #fff;
	background: rgba(1, 152, 255, 1);
}
.navHeader{
  position: sticky;
  top:0;
  z-index: 9;
}
.topBox{
  height: 4.2rem;
  width:100%;
  background-image: url("../../assets/image/freeSoftware/freewareD1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomDescBox{
  width:14.4rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.32rem;
  margin: 0.4rem auto;
}
.topContainerBox{
  width: 14.4rem;
  height: 3.55rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topContainerContentBox{
  width: 13.9rem;
  height: 3.15rem;
	border-radius: 0.28rem;
  background-image: url("../../assets/image/freeSoftware/freewareD2.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.ttopbox{
  height:0.64rem;
  width: 100%;
  margin-top:0.28rem;
  margin-left:0.5rem;
  display: flex;
}
.ttopbox>img{
  height:0.64rem;
  width:0.64rem;
  margin-right:0.24rem
}
.ttopbox .topDesc{
  height: 0.35rem;
  color: rgba(59, 59, 59, 1);
  font-size: 0.3rem;
  font-weight: 500;
}
.topDesc .question {
	width: 0.24rem;
	height: 0.24rem;
}
.ttopbox .topIcon{
  display: flex;
  margin-top:0.08rem;
  color: rgba(60, 119, 171, 1);
  font-size: 0.16rem;
  font-weight: 400;
}
.ttopbox .topIcon img{
  width:0.2rem;
  height:0.2rem
}
.ttopbox .topIcon>div{
  display: flex;
  align-items: center;
  margin-right:0.4rem
}
.midBox{
  width: 100%;
  margin-top:0.33rem;
  margin-left:0.5rem;
  color: rgba(56, 56, 56, 1);
  font-size: 0.2rem;
  font-weight: 400;
}
.midBox>div:first-child{
  height:0.4rem;
  line-height: 0.4rem;
  display: flex;
  align-items: center;
}
.midBox>div:nth-child(2){
  height:0.4rem;
  line-height: 0.4rem;
  display: flex;
  align-items: center;
}
.bottomBox{
  margin-top:0.35rem;
  margin-left:0.5rem;
}
.descTitleBox{
  display: flex;
  align-items: center;
  color: rgba(59, 59, 59, 1);
  font-size: 0.3rem;
  font-weight: 700;
  justify-content: center;
  padding: 0.32rem 0.32rem 0.4rem 0.4rem;
}
.descContentBox{
  width:12.8rem;
  margin: auto;
  padding-bottom:0.8rem;
  color: rgba(56, 56, 56, 1);
  letter-spacing: 0.02rem;
  font-size: 0.16rem;
  font-weight: 400;
}
.descContentBox >>> img {
  max-width: 100%;
}
</style>